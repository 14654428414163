import { Route, Routes } from "react-router-dom";
import ErrorPage from "./components/ErrorPage";
import Footer from "./components/Footer";
import Header from "./components/Header";
import MainHome from "./components/Pages/MainHome";
import Spacing from "./components/Spacing";
function App() {
  return (
    <>
      <Header />
      <Spacing lg="80" md="80" />
      <Routes>
        <Route path="/" element={<MainHome />} />
        {/*<Route path="/blog/:id" element={<BlogDetails/>}/>*/}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
