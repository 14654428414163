import React, {useEffect, useState} from "react";
import parse from "html-react-parser";
import "./hero.scss";
import Section from "../Section";
import Modal from "../Modal";

export default function Hero({
                                 title,
                                 mintNumber,
                                 mintMax,
                                 mintPrice,
                                 mintDeathLine,
                                 bgUrl,
                                 heroImageUrl,
                                 animatedUrl,
                                 variant,
                                 bubble,
                                 overlay,
                                 bgOpacity,
                             }) {

    const [pick, setPick] = useState();
    const [isHovered, setIsHovered] = useState(false);
    const [isMobile, setIsMobile] = useState(false);


    useEffect(() => {
        let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || window.innerWidth < 500;
        setIsMobile(isMobile)
    }, [])

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const pickToken = (num) => {
        console.log('picking...', num)
        setPick(num)
    }

    const chakraConfig = [
        {
            tokenId: 2,
            chakraImg: '/images/chakras/a-432-inverse-chakra-transparent.png',
            priceUsd: 43.20,
            priceInEth: 1.5,
            maxSupply: 432,
            type: 'image'
        },
        {
            tokenId: 3,
            chakraImg: '/images/chakras/a-432-rainbow-chakra-transparent.png',
            priceUsd: 43.20,
            priceInEth: 1.5,
            maxSupply: 432,
            type: 'image'
        },
        {
            tokenId: 4,
            chakraImg: '/images/chakras/ea-enki-crop-circle.png',
            priceUsd: 43.20,
            priceInEth: 1.5,
            maxSupply: 432,
            type: 'image'
        },
        {
            tokenId: 5,
            chakraImg: '/images/chakras/seven-star-crop.png',
            priceUsd: 43.20,
            priceInEth: 1.5,
            maxSupply: 432,
            type: 'image'
        },
        {
            tokenId: 1,
            chakraImg: '/images/chakras/a-432-inverse-chakra.png',
            priceUsd: 432,
            priceInEth: 1.5,
            maxSupply: 10,
            type: 'image'
        },
        {
            tokenId: 0,
            chakraImg: '/images/chakras/a-432-rainbow-chakra.png',
            priceUsd: 4_320,
            priceInEth: 1.5,
            maxSupply: 1,
            type: 'image'
        },
        {
            tokenId: 6,
            chakraImg: '/images/chakras/chakra-video.mov',
            priceUsd: 4_320,
            priceInEth: 1.5,
            maxSupply: 1,
            type: 'video'
        },
    ]

    return (
        <Section
            tag="section"
            className={`top-level-section-hero cs-hero cs-style1 space-bg ${variant ? variant : ""}`}
            id="hero"
        >
            <div className="rocket-launcher">
                <img
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => {
                        window.open('https://youtu.be/CAgMe8FTyWo?si=KUPMonsihHqAewab', '_blank');
                    }}
                    src={isHovered ? "/images/rocket-launch-red-cut.png" : "/images/rocket-launch-basic-cut.png"}
                    alt=""
                />
            </div>

            {overlay && (
                <Section className="cs-dark_overlay" style={{opacity: overlay}}/>
            )}
            <Section className="cs-hero_bg">
                <video className="landing-video" autoPlay loop muted src="/videos/video-bg-2.mp4"></video>
            </Section>
            <Section className="container nft-mint-section">
                {
                    isMobile && <>
                        <div id='stars'></div>
                        <div id='stars2'></div>
                        <div id='stars3'></div>
                    </>
                }
                <Section className="cs-hero_text custom-section-container">
                    <div className="mint-it-bg top-level-container">
                        <Section
                            tag="h2"
                            className="inverse-color cs-hero_secondary_title cs-font_24 cs-font_18_sm"
                        >{mintMax} Piece Collection</Section>

                        <Section
                            tag="h1"
                            className="inverse-color title-first cs-hero_title cs-font_64 cs-font_36_sm cs-bold shadowed-bg"
                        >{parse(title)}</Section>
                    </div>

                    {/* Pick It Section */}
                    <div className="mint-it-bg">
                        <h4 className="inverse-color">MLH 432 Lunar Collection</h4>
                        <h6 className="inverse-color">Pick your version</h6>

                        <div className="mint-section">
                            {
                                chakraConfig.map((item, index) => (
                                    <div
                                        className="mint-card-item"
                                        key={item.chakraImg}>
                                        <button
                                            style={{margin: '0.2rem', padding: '0.6rem'}}
                                            onClick={() => pickToken(item.tokenId)}
                                            className={`cs-btn inverse-color cs-color1 ${pick === index ? 'active' : ''}`}
                                        >
                                            {
                                                item.type === 'image' ?
                                                    <img src={item.chakraImg} width={48} height={48} alt=""
                                                         style={{borderRadius: '10px'}}/> :
                                                    <video src={item.chakraImg} width={48} height={48} autoPlay loop
                                                           muted>
                                                    </video>
                                            }
                                            <div className="chakra-figures-container">
                                                {/* Either ETH Price API or simply show only ETH prices... */}
                                                <span
                                                    className="mint-item-label">{(item.priceUsd / 3500).toFixed(2)} ETH</span>
                                                <span
                                                    className="mint-item-label supply">{item.maxSupply} {item.maxSupply > 1 ? "Pieces" : "Piece"}</span>
                                            </div>
                                        </button>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    {/* Mint it Section */}
                    <div className="mint-it">
                        <div>
                            <h4 className="inverse-color">Mint your NFT now </h4>
                            <Section className="cs-btn_group action-group">
                                <Modal
                                    disabled={isNaN(pick)} modalType="mint"
                                    tokenId={pick}
                                    btnText={pick >= 0 ? "Mint Now" : "Pick a version"}/>
                                <a href="https://www.youtube.com/watch?v=BHNhExrVKXc"
                                   target="_blank" className={`cs-btn cs-btn_filed cs-accent_btn`}>
                                    <Section tag='span'>432hz in action</Section>
                                </a>
                            </Section>
                        </div>
                        <div className="signature-image-container">
                            <img className="signature-image" src="/images/signature.png" alt=""/>
                        </div>
                    </div>

                    <Section
                        style={{fontSize: '14px'}}
                        tag="h5"
                        className="cs-hero_subtitle cs-font_18 cs-font_16_sm cs-body_line_height shadowed-bg"
                    ></Section>
                </Section>
            </Section>
            {bubble && <Section id="background-wrap"></Section>}
        </Section>
    );
}
