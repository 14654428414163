import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll'
import Section from '../Section';
import './header.scss'

export default function Header() {
  const [mobileToggle, setMobileToggle] = useState(false)
  return (
    <>
      <Section tag='header' className="cs-site_header cs-style1 cs-sticky-header cs-primary_color space-bg">
        <Section className="cs-main_header">
          <Section className="container">
            <Section className="cs-main_header_in">
              <Section className="cs-main_header_left">
                <Link to='/' className="cs-site_branding cs-accent_color">
                  <img src="/images/signature.png" alt="Logo" className="cs-hide_dark signature-image-border" />
                  <img src="/images/inverse-chakra-transp.png" alt="Logo" className="cs-hide_white" />
                </Link>
              </Section>
              <Section className="cs-main_header_center">
                <Section className="cs-nav">
                  <ul className="cs-nav_list custom-nav-bar" style={{display:`${mobileToggle?'block':'none'}`}}>
                    <li><ScrollLink className="scroll-link" to="hero" spy={true} smooth={true} offset={-80} duration={500} onClick={()=>setMobileToggle(false)}>Home</ScrollLink></li>
                    <li><ScrollLink className="scroll-link" to="about" spy={true} smooth={true} offset={-80} duration={500} onClick={()=>setMobileToggle(false)}>Secondary Market</ScrollLink></li>
                    <li><ScrollLink className="scroll-link" to="roadmap" spy={true} smooth={true} offset={-80} duration={500} onClick={()=>setMobileToggle(false)}>Roadmap</ScrollLink></li>
                    <li><ScrollLink className="scroll-link" to="team" spy={true} smooth={true} offset={-80} duration={500} onClick={()=>setMobileToggle(false)}>Team</ScrollLink></li>
                    <li><ScrollLink className="scroll-link" to="science" spy={true} smooth={true} offset={-80} duration={500} onClick={()=>setMobileToggle(false)}>Science</ScrollLink></li>
                    <li><ScrollLink className="scroll-link" to="faq" spy={true} smooth={true} offset={-80} duration={500} onClick={()=>setMobileToggle(false)}>FAQ</ScrollLink></li>
                  </ul>
                  <span className={mobileToggle?"cs-munu_toggle cs-toggle_active":"cs-munu_toggle"} onClick={()=>setMobileToggle(!mobileToggle)}><span></span></span>
                </Section>
              </Section>
            </Section>
          </Section>
        </Section>
      </Section>
    </>
  )
}
