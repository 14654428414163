import {Icon} from "@iconify/react";
import React, {useState} from "react";
import ConnectCard from "../ConnectCard";
import MintCard from "../MintCard";
import Section from "../Section";
import Button from "../Button";

export default function Modal({modalType, btnText, tokenId, disabled, btnIcon}) {
    const [modal, setModal] = useState(false);

    const uri = `https://embed.ipfscdn.io/ipfs/bafybeigdie2yyiazou7grjowoevmuip6akk33nqb55vrpezqdwfssrxyfy/erc1155.html
                                    ?contract=0x50D74e8c5B7E7ECB6e376EF3a898124d7c1F08d6&chain=%7B%22name%22%3A%22Sepolia%22%2C%22chain%22%3A%22ETH%22%2C%22rpc%22%3A%5B%22https%3A%2F%2Fendpoints.omniatech.io%2Fv1%2Feth%2Fsepolia%2Fpublic%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22Sepolia+Ether%22%2C%22symbol%22%3A%22ETH%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22sep%22%2C%22chainId%22%3A11155111%2C%22testnet%22%3Atrue%2C%22slug%22%3A%22sepolia%22%2C%22icon%22%3A%7B%22url%22%3A%22ipfs%3A%2F%2FQmcxZHpyJa8T4i63xqjPYrZ6tKrt55tZJpbXcjSDKuKaf9%2Fethereum%2F512.png%22%2C%22width%22%3A512%2C%22height%22%3A512%2C%22format%22%3A%22png%22%7D%7D
                                    &clientId=43c6ec83960df593fdff7ae99b3ce2d8&tokenId=${tokenId}&theme=dark&primaryColor=blue`

    return (
        <>
            <button
                disabled={disabled}
                className={`cs-btn cs-btn_filed ${disabled ? 'disabled' : ''}`}
                onClick={() => setModal(!modal)}
            >
                {btnIcon && <Icon icon="ion:wallet-outline"/>}
                {btnText}
            </button>

            <Section className={modal ? "cs-modal active" : "cs-modal"}>
                <Section className="cs-modal_in">
                    <Section className="cs-modal_container">
                        <Section className="cs-modal_container_in cs-light_bg">
                            <button
                                className="cs-close_modal cs-center cs-primary_bg"
                                onClick={() => setModal(!modal)}
                            >
                                <svg
                                    width={12}
                                    height={12}
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M11.7071 1.70711C12.0976 1.31658 12.0976 0.683417 11.7071 0.292893C11.3166 -0.0976311 10.6834 -0.0976311 10.2929 0.292893L11.7071 1.70711ZM0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683417 12.0976 1.31658 12.0976 1.70711 11.7071L0.292893 10.2929ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L10.2929 11.7071ZM10.2929 0.292893L0.292893 10.2929L1.70711 11.7071L11.7071 1.70711L10.2929 0.292893ZM0.292893 1.70711L10.2929 11.7071L11.7071 10.2929L1.70711 0.292893L0.292893 1.70711Z"
                                        fill="white"
                                    />
                                </svg>
                            </button>
                            {modalType === "mint" && (
                                <iframe
                                    src={uri}
                                    width="600px"
                                    height="600px"
                                    style={{
                                        maxWidth: "100%", zIndex: 10,
                                        position: 'absolute',
                                        left: 0,
                                        top: '25%',
                                        transform: 'translateY(-50 %'
                                    }}
                                    frameBorder="0"
                                ></iframe>
                            )}
                            {modalType === "mint_depr" && <MintCard/>}
                            {modalType === "connect" && <ConnectCard/>}
                        </Section>
                    </Section>
                </Section>
            </Section>
        </>
    );
}
