import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";
import Cta from "../Cta";
import Section from "../Section";
import "./footer.scss";

export default function Footer() {
  return (
    <Section
      tag="footer"
      className="cs-footer_wrap cs-bg"
      style={{ backgroundImage: "url(/images/bg-shaped.jpg)" }}
    >
      <Section className="container">
        <Cta />
      </Section>
      <Section tag="footer" className="cs-footer text-center">
        <Section className="container">
          <Section className="cs-height_100 cs-height_lg_70" />
          <Section className="cs-footer_logo">
            <img
              src="/images/inverse-chakra-transp.png"
              alt="logo"
              className="cs-hide_dark"
              width={100}
            />
            <img
              src="/images/inverse-chakra-transp.png"
              alt="logo"
              className="cs-hide_white"
              width={100}
            />
          </Section>
          <Section className="cs-height_25 cs-height_lg_25" />
          <Section className="cs-social_btns cs-center">
            <a
              href="https://www.facebook.com/MLH5150"
              className="cs-center cs-primary_color cs-accent_bg_hover cs-light_bg cs-white_color_hover"
              target="_blank"
            >
              <Icon icon="fa6-brands:facebook-f" />
            </a>
            {/*<Link to="/" className="cs-center cs-primary_color cs-accent_bg_hover cs-light_bg cs-white_color_hover">
              <Icon icon="fa6-brands:twitter" />            
            </Link>
            <Link to="/" className="cs-center cs-primary_color cs-accent_bg_hover cs-light_bg cs-white_color_hover">
              <Icon icon="fa6-brands:instagram" />
            </Link>*/}
            <a
              href="https://www.youtube.com/@FrozenHill"
              className="cs-center cs-primary_color cs-accent_bg_hover cs-light_bg cs-white_color_hover"
              target="_blank"
            >
              <Icon icon="fa6-brands:youtube" />
            </a>
            {/*<Link to="/" className="cs-center cs-primary_color cs-accent_bg_hover cs-light_bg cs-white_color_hover">
              <Icon icon="fa6-brands:whatsapp" />              
            </Link>*/}
          </Section>
        </Section>
        <Section className="cs-height_45 cs-height_lg_25" />
        <Section className="container">
          <Section className="cs-copyright text-center">
            Copyright © 2024. All Rights Reserved by{" "}
            <span className="cs-primary_font cs-primary_color">
              Michael Lee Hill
            </span>
          </Section>
        </Section>
        <Section className="cs-height_25 cs-height_lg_25" />
      </Section>
    </Section>
  );
}
