import { Icon } from "@iconify/react";
import React from "react";
import Slider from "react-slick";
import Section from "../Section";
import CardStype2 from "../Card/CardStype2";

export default function RoadmapSlider() {
  const data = [
    {
      phase: "Phase 1",
      title: "Launch collection",
      subtitle:
        "Introducing the Cosmic Frequency Collection: Where Art, Science, and Divine Harmony Collide!",
    },
    {
      phase: "Phase 2",
      title: "Community Build",
      subtitle:
        "Further growing the 432HZ community by providing more value, educating people and giving back.",
    },
    {
      phase: "Phase 3",
      title: "DAO Launch",
      subtitle:
        "Launch of a simple DAO to give you a vote that counts! Let's build something together that is here to stay.",
    },
    {
      phase: "Phase 4",
      title: "Luna Aqua 432",
      subtitle:
        "Launch of the Luna Aqua 432 water bottles. Water that has been restructured with the 432HZ frequency.",
    },
  ];

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="material-symbols:chevron-left-rounded" />
    </div>
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="material-symbols:chevron-right-rounded" />
    </div>
  );
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1285,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="cs-gap-24 cs-awwow_style_1 cs-type1">
      {data.map((item, index) => (
        <Section key={index}>
          <CardStype2
            cardNumber={index + 1}
            phase={item.phase}
            title={item.title}
            subtitle={item.subtitle}
          />
        </Section>
      ))}
    </Slider>
  );
}
