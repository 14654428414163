import { Icon } from "@iconify/react";
import React from "react";
import Button from "../Button";
import Section from "../Section";
import "./cta.scss";

export default function Cta() {
  return (
    <Section
      className="cs-cta cs-style1  text-center"
      style={{ marginTop: "5rem" }}
    >
      <Section
        tag="h2"
        className="cs-cta_title cs-white_color cs-font_64 cs-font_36_sm cs-m0"
      >
        Become part of
        <br />
        our community
      </Section>
      <Section className="cs-height_10 cs-height_lg_10" />
      <Section className="cs-cta_subtitle cs-white_color">
        The 432HZ community is steadily growing.
      </Section>
      <Section className="cs-height_30 cs-height_lg_30" />
      <Section className="cs-cta_btns cs-center">
        <a
          href="https://www.facebook.com/MLH5150"
          target="_blank"
          className="cs-btn cs-btn_filed cs-white_btn_2"
        >
          <Icon icon="fluent:people-community-add-28-regular" />
          Join Our Community
        </a>
        <a
          href="http://www.michaelleehill.net"
          target="_blank"
          className="cs-btn cs-btn_filed cs-white_btn_2"
        >
          Browse Merchandise
        </a>
      </Section>
    </Section>
  );
}
